import React from 'react';
import Layout from '../components/layout';
import { css } from '@emotion/core';
import { Helmet } from 'react-helmet';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import {
  FaBitbucket,
  FaGithub,
  FaGitlab,
  FaDocker,
  FaLinkedin,
  FaXing,
} from 'react-icons/fa';

const Social = css`
  margin-top: 20px;
  margin-left: 8px;
  margin-right: 8px;

  a {
    color: #000;
    &:hover {
      color: #007bff;
    }
  }
`;

const Index = ({ data }) => {
  return (
    <Layout>
      <Helmet title={'Tom Beyer: My private blog'} />
      <div className="about text-center">
        <div className="avatar">
          <Img
            className="img-fluid rounded-circle"
            fixed={data.file.childImageSharp.fixed}
          />
        </div>

        <h1 className="fullname display-3">Tom Beyer</h1>
        <h3
          className="display-4 jobtitle"
          css={css`
            margin-bottom: 20px;
          `}
        >
          Software Architect
        </h3>
        <p>
          My name is Tom Beyer and I work as a{' '}
          <strong>SAP software architect</strong> and{' '}
          <strong>project manager</strong> for{' '}
          <a href="https://www.draexlmaier.com">
            <strong
              css={css`
                color: #4e4e4e;
              `}
            >
              DRÄXLMAIER
            </strong>
          </a>{' '}
          in Vilsbiburg, Germany.
        </p>
        <p>
          I focus on{' '}
          <strong>
            data management, master data governance, data migrations
          </strong>{' '}
          and I’m enthusiastic about{' '}
          <strong>
            cutting edge technology, system architecture, cloud computing,
            Docker and tinkering
          </strong>
          .
        </p>
        <ul className="list-inline">
          <li className="list-inline-item" css={Social}>
            <a href="https://github.com/beyertom/">
              <FaGithub size={32} />
            </a>
          </li>
          <li className="list-inline-item" css={Social}>
            <a href="https://hub.docker.com/u/galdan/">
              <FaDocker size={32} />
            </a>
          </li>
          <li className="list-inline-item" css={Social}>
            <a href="https://gitlab.com/beyertom/">
              <FaGitlab size={32} />
            </a>
          </li>
          <li className="list-inline-item" css={Social}>
            <a href="https://www.xing.com/profile/Thomas_Beyer74/cv/">
              <FaXing size={32} />
            </a>
          </li>
          <li className="list-inline-item" css={Social}>
            <a href="https://www.linkedin.com/in/thomas-beyer-2b243a9a/">
              <FaLinkedin size={32} />
            </a>
          </li>
          <li className="list-inline-item" css={Social}>
            <a href="http://bitbucket.org/beyerthomas/">
              <FaBitbucket size={32} />
            </a>
          </li>
        </ul>
      </div>
    </Layout>
  );
};

export default Index;

export const query = graphql`
  query {
    file(relativePath: { eq: "tom.jpg" }) {
      childImageSharp {
        fixed(width: 400, height: 400) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;
